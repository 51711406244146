<script>
import ConfirmationModal from './ConfirmationModal.vue'
export default {
  components: { ConfirmationModal },
  name: 'InviteListItem',
  props: {
    invite: {
      type: Object
    }
  },
  data () {
    return {
      showConfirmationModal: false,
      showConfirmationReactivateModal: false
    }
  },
  methods: {
    removeInvite (email) {
      this.showConfirmationModal = false
      this.$store.dispatch('attemptDeleteInvite', email)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.admincenter.invite.labels:remove.success')
          })
          this.$emit('updateInviteList')
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('settings.admincenter.invite.labels:remove.error')
          })
        })
    },
    resendInvite (email) {
      this.showConfirmationReactivateModal = false
      this.$store.dispatch('attemptResendInvite', email)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.admincenter.invite.labels:resend.success')
          })
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('settings.admincenter.invite.labels:resend.error')
          })
        })
    }
  }
}
</script>
<template>
  <div class="invite-list-item--wrapper">
    <div class="invite-list-item--left">
      <div class="invite-list-item--info">
        <div class="invite-list-item--upper-info-wrapper">
          <p>{{ invite.email }}</p>
          <div class="invite-list-item--status" v-if="$te(`user.list.item:status.${invite.status}`)">
            <div class="icon-wrapper">
              <v-icon size="12px" color="#fff">mdi-dots-horizontal</v-icon>
            </div>
            <span>{{ $t(`user.list.item:status.${invite.status}`) }}</span>
          </div>
          <div class="invite-list-item--email">
            <span>{{invite.invitedBy === '$PLOORAL_SUPPORT' ? $t('user.list.item:plooralSupport') : $t('user.list.item:invitedBy', { name: invite.invitedBy }) }}</span>
          </div>
        </div>
        <span>{{ $t(`modal.invite.member:roles.${invite.role}`) }}</span>
      </div>
    </div>
    <div class="invite-list-item--right">
      <v-btn class="btn ml-4" color="#fff" @click="showConfirmationReactivateModal = true"><v-icon size="18" :color="getPrimaryColor">mdi-undo-variant</v-icon></v-btn>
      <v-btn class="btn ml-4" color="#fff" @click="showConfirmationModal = true"><v-icon size="18" :color="getPrimaryColor">mdi-delete</v-icon></v-btn>
    </div>
    <confirmation-modal
      :show="showConfirmationModal"
      :title="$t('invite.list.item:remove.title', { email: invite.email })"
      :description="$t('invite.list.item:remove.description', { email: invite.email })"
      :confirmText="$t('global:remove')"
      :btnConfirmText="false"
      @confirm="removeInvite(invite.email)"
      :cancelText="$t('global:cancel')"
      @cancel="showConfirmationModal = false"
    />
    <confirmation-modal
      :show="showConfirmationReactivateModal"
      :title="$t('invite.list.item:reactivate.title', { email: invite.email })"
      :description="$t('invite.list.item:reactivate.description', { email: invite.email })"
      :confirmText="$t('global:resend.invite')"
      :btnConfirmText="false"
      @confirm="resendInvite(invite.email)"
      :cancelText="$t('global:cancel')"
      @cancel="showConfirmationReactivateModal = false"
    />
  </div>
</template>
<style lang="scss">
.invite-list-item--wrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 20px;
  .invite-list-item--left {
    display: flex;
    align-items: center;
    height: 40px;
    .invite-list-item--info {
      text-align: left;
      .invite-list-item--upper-info-wrapper {
        display: flex;
        align-items: center;
        .invite-list-item--status {
          display: flex;
          align-items: center;
          background: linear-gradient(0deg, rgba(243, 170, 24, 0.1), rgba(243, 170, 24, 0.1)), #FFFFFF;
          border-radius: 100px;
          padding: 4px 8px;
          margin-left: 8px;
          .icon-wrapper {
            height: 14px;
            width: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F3AA18;
            margin-right: 8px;
            border-radius: 100px;
          }
          span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 12px;
            color: #F3AA18;
          }
        }
        p {
          margin-bottom: 0px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #212121;
        }
        .invite-list-item--email {
          background: linear-gradient(0deg, rgba(160, 160, 160, 0.1), rgba(160, 160, 160, 0.1)), #FAFAFA;
          border-radius: 900px;
          padding: 2px 8px;
          margin-left: 8px;
          span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 12px;
            color: #A0A0A0;
          }
        }
      }
      span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #A0A0A0;
      }
    }
  }
  .invite-list-item--right {
    display: flex;
    align-items: center;
    .v-btn {
      height: 30px;
      max-height: 30px;
      width: 30px;
      min-width: unset;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
