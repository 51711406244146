<script>
import InputField from '@/components/general/InputField'
import InviteListItem from '@/components/general/InviteListItem'
import EmptyList from '@/components/general/EmptyList'
import Loading from '@/components/general/Loading.vue'
export default {
  components: { InputField, InviteListItem, EmptyList, Loading },
  name: 'RemovedUsers',
  data () {
    return {
      search: '',
      isReady: false,
      invites: [],
      data: []
    }
  },
  created () {
    this.getInvites()
  },
  methods: {
    getInvites () {
      this.$store.dispatch('attemptGetInvites')
        .then(({ data }) => {
          this.isReady = true
          this.invites = data.filter(i => !i.accepted && i.active).map((invite) => (
            {
              email: invite.email,
              role: invite.role,
              firstInviteDate: invite.firstInviteDate,
              invitedBy: invite.invitedBy
            }
          ))
          this.invites.sort((a, b) => a.firstInviteDate.localeCompare(b.firstInviteDate))
          this.data = this.invites
        })
    }
  },
  watch: {
    search: function (value) {
      this.invites = this.data.filter(invite => {
        return invite.email.toLowerCase().indexOf(value.toLowerCase()) > -1
      })
    }
  }
}
</script>
<template>
  <div>
    <Loading v-if="!isReady"/>
    <div class="removed-users--container" v-else>
      <div class="removed-users--filter-actions">
        <input-field class="mr-2" outlined dense :label="$t('global:search.alt')" v-model="search" append-icon="mdi-magnify"></input-field>
      </div>
      <div class="removed-users--list">
        <empty-list
          v-if="invites.length == 0"
          :title="$t('empty.state.title:active.invite.removed.title')"
          :textOne="$t('empty.state.text:active.user.invite.text')"
          :image="'/assets/images/empty-bush.png'"
        ></empty-list>
        <invite-list-item v-for="(invite, index) in invites" :key="index" :invite="invite" @updateInviteList="getInvites()"></invite-list-item>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.removed-users--container {
  .removed-users--filter-actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
